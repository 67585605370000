import axios from '@/api/axios'
import { permissionCodes as codes } from '@/routes/permission'
import { appUrl } from '@/api/baseUrl'
const importExportRecords = {
    queryExportList (params) {
        return axios.get(appUrl + '/task/exportLog/page', {
            params, headers: {
                OperationCode: codes.importExportRecords.list
            }
        })
    },
    queryImportList (params) {
        return axios.get(appUrl + '/task/importLog/page', {
            params, headers: {
                OperationCode: codes.importExportRecords.list
            }
        })
    },
}
export default importExportRecords
