import React, { useState, useEffect, useRef } from "react";
import { Route, Redirect } from "react-router-dom";
import { useCustomState } from '@/hooks/useEffect';
import { connect } from 'react-redux'
import api from '@/api/apiList'
import axios from '@/api/axios'
import { baseUrl } from '../api/baseUrl';
import { apps, getFullLink } from '@/micro-app'
import { getMenuCode } from '../utils/index.js'
import nprogress from 'nprogress'
import { isLowerIEVersion, searchToObject } from '@/utils'
import { storage } from '@/utils/storage'
import config from '@/config'
import { Spin } from 'antd';
import {
    ADD_TABS,
    REMOVE_TABS,
    CHANGE_TABS,
    FILTER_TABS,
} from '@/store/actionTypes'

const FrontendAuth = (props) => {
    const { routerConfig, location } = props;
    const { pathname } = location;
    nprogress.configure({ easing: 'ease', speed: 500, showSpinner: false })
    useCustomState(nprogress.start());
    const [iframeLoaded, setIframeLoaded] = useState(false)
    const query = new URLSearchParams(location.search)
    const iframes = useRef([])
    useEffect(() => {
        nprogress.done();
        // return () => nprogress.start();
    });
    useEffect(() => {
        const thirdLogin = async (code, jfCode) => {
            let res = await axios.post(`${baseUrl}/thirdLogin/authSkip`, { id: 1, code, jfCode, loading: true, text: '正在登录，请稍后...' })
            if (res && res.success) {
                storage.set('token', encodeURIComponent(res.result.token), 'cookie')
                const res2 = await api.app.menuList({ dataType: 1001 })
                const res3 = await api.app.menuList({ dataType: 1003 })
                const res4 = await api.app.menuList({ dataType: 1004 })
                if (res2 && res4 && res3 && res2.success && res4.success && res3.success) {
                    let menuList = res4.result && res4.result.menuInfo && res4.result.menuInfo.menuList
                    menuList = JSON.parse(menuList)
                    let menuCodes = getMenuCode(menuList)
                    let permissionCodes = res3.result && res3.result.btnInfo && res3.result.btnInfo.btnList
                    let codeArr = [...permissionCodes, ...menuCodes]
                    let newData = Object.assign({}, res.result, res2.result && res2.result.baseInfo, { btnList: codeArr }, res4.result && res4.result.menuInfo)
                    await props.setUserInfo(newData)
                }

                setTimeout(() => {
                    props.addTabs({ activeKey: pathname })
                }, 200);
            }
        }
        if (query.get('code') && query.get('jfCode') && !storage.get('token', 'cookie')) {
            thirdLogin(query.get('code'), query.get('jfCode'))
        }
        loadingIframe()
    }, [])
    useEffect(() => {
        if (location.search && iframeLoaded) {
            let domain = apps[routerConfig.path.split('/')[1]].url
            // console.log(searchToObject(location.search), 'searchToObject');
            iframes.current?.contentWindow.postMessage({ refresh: true, query: searchToObject(location.search) }, domain)
        }
    }, [location.search]);
    const setTitle = (title) => {
        document.title = config.setting.title + '-' + (routerConfig.meta?.title || routerConfig.title)
        props.panes && props.panes.map(el => {
            if (el.key === pathname) {
                document.title = config.setting.title + '-' + (el.meta?.title || el.title)
            }
        })
        return !!document.title.split('-')[1]
    }
    const loadingIframe = () => {
        let iframe = iframes.current
        if (iframe !== null) {
            if (iframe.attachEvent) {
                iframe.attachEvent('onload', function () {
                    setIframeLoaded(true)
                    let domain = apps[routerConfig.path.split('/')[1]].url
                    const userInfo = storage.get('userInfo')
                    if (userInfo && userInfo !== null) {
                        iframe?.contentWindow.postMessage(JSON.stringify(userInfo), domain)
                    }
                })
            } else {
                iframe.onload = function () {
                    setIframeLoaded(true)
                    let domain = apps[routerConfig.path.split('/')[1]].url
                    const userInfo = storage.get('userInfo')
                    if (userInfo && userInfo !== null) {
                        iframe?.contentWindow.postMessage(JSON.stringify(userInfo), domain)
                    }
                }
            }
        }
    }
    const render = () => {
        const token = props.userInfo?.token || storage.get('token', 'cookie')
        // setIsLogin(Cookies.get('token'))
        // 如果该路由不用进行权限校验，登录状态下登陆页除外
        // 因为登陆后，无法跳转到登陆页
        // 这部分代码，是为了在非登陆状态下，访问不需要权限校验的路由
        const targetRouterConfig = routerConfig
        // 判断ie < 11直接跳转下载推荐浏览器
        // console.log(props, 'props');
        let lowerBrowser = isLowerIEVersion() && isLowerIEVersion() < 11
        if (lowerBrowser && pathname !== '/browser') {
            return <Redirect to="/browser" />;
        }
        // 设置网站 title
        if (!setTitle()) {
            return <Redirect to="/404" />;
        }
        if (token) {
            storage.set('token', token, 'cookie')
            // 如果是登陆状态，想要跳转到登陆，重定向到主页
            if (pathname === "/login") {
                props.changeTabs({ activeKey: '/' })
                return <Redirect to="/" />;
            } else {
                // 如果路由合法，就跳转到相应的路由
                // console.log(targetRouterConfig.meta.roles, 'targetRouterConfig.meta.roles');
                // hasPermission([targetRouterConfig.meta.roles])
                // 微应用
                if (targetRouterConfig) {
                    return <Route exact={Boolean(targetRouterConfig.exact)} path={pathname} render={(pProps) => {
                        return props.isIframeActive ? <div className="iframe-pages" style={{ height: '100%', width: '100%', visibility: pathname === targetRouterConfig.path ? 'visible' : 'hidden' }}>
                            <iframe
                                id={targetRouterConfig.path}
                                ref={iframes}
                                width="100%"
                                height="100%"
                                // style={{display: pathname === targetRouterConfig.path ? 'block' : 'none'}}
                                key={targetRouterConfig.path}
                                name={targetRouterConfig.path}
                                frameBorder="0"
                                src={targetRouterConfig.url} />
                            {/* !iframeLoaded &&  */}
                            {!iframeLoaded && <Spin style={{
                                position: 'absolute',
                                height: '100%', width: '100%',
                                top: 0, left: 0
                            }}
                                tip={'拼命加载中，请稍候...'}
                                spinning={!iframeLoaded} />}
                        </div> : <targetRouterConfig.component {...props} {...pProps} routes={targetRouterConfig.children}></targetRouterConfig.component>
                    }}></Route>
                } else {
                    // 如果路由不合法，重定向到 404 页面
                    return <Redirect to="/404" />;
                }
            }
        } else {
            // 非登陆状态下，当路由合法时且需要权限校验时，跳转到登陆页面，要求登陆
            if (!targetRouterConfig) {
                // 非登陆状态下，路由不合法时，重定向至 404
                return <Redirect to="/404" />;
            }
            if (targetRouterConfig && !targetRouterConfig.auth && !props.isIframeActive) {
                return <Route exact={Boolean(targetRouterConfig.exact)} path={pathname} render={(pProps) => {
                    return <targetRouterConfig.component {...props} {...pProps} routes={targetRouterConfig.children}></targetRouterConfig.component>
                }}></Route>
            } else if (targetRouterConfig && (targetRouterConfig.auth || props.isIframeActive)) {
                console.log('未登录，跳转登录')
                // message.error('登录信息失效，请重新登录！')
                storage.remove('userInfo')
                storage.remove('tabList')
                storage.remove('aliveApps')
                return <Redirect to="/login" />;
            } else {
                // 非登陆状态下，路由不合法时，重定向至 404
                return <Redirect to="/404" />;
            }
        }
    }
    return render()
}

function arePropsEqual (prevProps, nextProps) {
    const prevPath = prevProps.location.pathname
    const nextPath = nextProps.location.pathname
    const prevScroll = prevProps.scroll || {}
    const nextprevScroll = nextProps.scroll || {}
    nprogress.done();
    // console.log(prevPath, nextPath, 'nextPath');
    if (prevPath === nextPath) {
        if (prevScroll.y !== nextprevScroll.y) {
            return false
        }
        return true
    }
    let _prev = JSON.stringify(prevProps)
    let _next = JSON.stringify(nextProps)
    return _prev === _next
    // return false
}
//需要触发什么行为
function mapDispatchToProps (dispatch, props) {
    return {
        onLogoClick: (activeKey) => {
            dispatch({
                type: CHANGE_TABS,
                ...props,
                activeKey
            })
        },
        setTheme: (val) => dispatch({
            type: 'setTheme',
            value: {
                ...val
            }
        }),
        addTabs: async (val) => {
            await dispatch({
                type: ADD_TABS,
                ...props,
                ...val
            })
        },
        removeTabs: (activeKey, isCloseOpen) => dispatch({
            type: REMOVE_TABS,
            activeKey,
            isCloseOpen,
            ...props
        }),
        changeTabs: (val) => {
            if (props.location.pathname === val.activeKey) return
            dispatch({
                type: CHANGE_TABS,
                ...props,
                ...val
            })
        },
        filterTabs: (val) => dispatch({
            type: FILTER_TABS,
            value: val,
            ...props
        }),
        logout: (value) => {
            dispatch({
                type: 'logout',
                value,
                ...props
            })
        },
        setUserInfo: async (value) => {
            await dispatch({
                type: 'setUserInfo',
                value,
                ...props
            })
        },
    }
}
// React.memo 可接受2个参数，第一个参数为纯函数的组件，第二个参数用于对比props控制是否刷新
// 类似 shouldComponentUpdate()，与 shouldComponentUpdate 不同的是，
// arePropsEqual 返回 true 时，不会触发 render，如果返回 false，则会。
// 而 shouldComponentUpdate 刚好与其相反。
export default connect(() => ({}), mapDispatchToProps)(React.memo(FrontendAuth, arePropsEqual))