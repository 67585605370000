import { storage } from '@/utils/storage'

/**
 * 判断菜单或者按钮是否有该权限
 * @param {*} codes 权限码集合 array
 * @returns {Boolean}
 */
export const hasPermission = (codes) => {
    const userInfo = storage.get('userInfo')
    if (userInfo && userInfo !== null) {
        // console.log(userInfo, codes, 'codes')
        return userInfo.btnList && userInfo.btnList.some(item => codes.includes(item))
        // return true
    }
    return false
}

export const permissionCodes = {
    user: { // 用户管理
        manage: 'user_manage', // 用户管理
        list: 'user_mgr_management', // 用户管理
        add: 'user_mgr_add', // 新增用户
        edit: 'user_mgr_edit', // 编辑
        del: 'user_mgr_del', // 删除
        Assign_roles: 'user_mgr_Assign_roles', // 分配角色
        reset_password: 'user_mgr_reset_password', // 重置密码
        status: 'user_mgr_status', // 状态开关
    },
    role: { // 角色管理
        list: 'user_role', // 角色管理
        add: 'user_role_add', // 角色新增
        edit: 'user_role_edit', // 修改角色
        remove: 'user_role_remove', // 角色删除
        authority: 'user_role_authority', // 配置权限
    },
    dept: { // 部门管理
        tree: 'user_dept', // 部门管理
        add: 'user_dept_add', // 新增部门
        edit: 'user_dept_edit', // 修改部门管理
        remove: 'user_dept_remove', // 删除部门管理
        sort: 'user_dept_sort', // 部门排序
    },
    application: { // 应用管理
        list: 'user_application', // 应用管理
        add: 'user_application_add', // 新增应用
        edit: 'user_application_edit', // 应用修改
        remove: 'user_application_remove', // 应用删除
        sort: 'user_application_sort', // 应用排序
    },
    logCenter: { // 应用管理
        manage: 'log_manage',
        list: 'user_operation_log', // 应用管理
        config: 'config_system_page', // 新增应用
    },
    importExportRecords: {
        list: 'import_or_export_log'
    },
    admin: { // 基础设置
        user_admin: 'user_admin',
        category: { // 分类管理员
            list: 'user_category_admin', // 分类管理员列表
            add: 'user_category_admin_add', // 新增管理员
            delete: 'user_category_admin_delete', // 移除管理员
            exchange: 'user_category_admin_exchange', // 替换管理员
            export: 'user_category_admin_export', // 导出管理员
        },
        customer: { // 客户管理员
            list: 'customer_admin_order_list', // 客户管理员列表
            update: 'customer_admin_order_add', // 新增管理员
            delete: 'customer_admin_order_delete', // 移除管理员
            exchange: 'customer_admin_order_exchange', // 替换管理员
            export: 'customer_admin_order_export', // 导出管理员
        },
        supplier: { // 供应商管理员
            list: 'supplier_admin_order_list', // 供应商管理员列表
            update: 'supplier_admin_order_add', // 新增管理员
            delete: 'supplier_admin_order_delete', // 移除管理员
            exchange: 'supplier_admin_order_exchange', // 替换管理员
            export: 'supplier_admin_order_export', // 导出管理员
        }
    },
    stock_center_manage: 'stock_center_manage',
    project_submission_manage: 'project_submission_manage',
    order_center: 'order_center',
    operation_center: 'operation_center',
    finance_center: 'finance_center',
    goods_center: 'goods_center',
    selection_center: 'selection_center',
    supplier_center: 'supplier_center',
    user_center: 'user_center', // 用户中心， 查看个人信息，编辑个人信息，退出登录，修改密码
    index: 'user_index', // 我的应用/首页
    appCode: 'yzh-user-service', // 用户中心系统
    orderCneterCode: 'yzh-order-service' // 用户中心系统
}
export const { permissionCodes: pCodes } = permissionCodes;