// import React, { useEffect } from 'react';
// import { TreeSelect } from 'antd';
// import PropTypes from 'prop-types';
// const { SHOW_PARENT } = TreeSelect;

// const treeData = [
//   {
//     title: 'Node1',
//     value: '0-0',
//     key: '0-0',
//     children: [
//       {
//         title: 'Child Node1',
//         value: '0-0-0',
//         key: '0-0-0',
//       },
//     ],
//   },
//   {
//     title: 'Node2',
//     value: '0-1',
//     key: '0-1',
//     children: [
//       {
//         title: 'Child Node3',
//         value: '0-1-0',
//         key: '0-1-0',
//       },
//       {
//         title: 'Child Node4',
//         value: '0-1-1',
//         key: '0-1-1',
//       },
//       {
//         title: 'Child Node5',
//         value: '0-1-2',
//         key: '0-1-2',
//       },
//     ],
//   },
// ];

// class TreeComponent extends React.Component {
//   state = {
//     value: ['0-0-0'],
//   };

//   onChange = value => {
//     console.log('onChange ', value);
//     this.setState({ value });
//   };

//   filterTreeNode = (input, option) => {
//         console.log(input, option)
//         return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
//     }

//   render() {
//     const tProps = {
//       treeData,
//       value: this.state.value,
//       onChange: this.onChange,
//       treeCheckable: true,
//       showCheckedStrategy: SHOW_PARENT,
//       searchplaceholder: '请选择分类',
//       filterTreeNode: this.filterTreeNode,
//       style: {
//         width: '100%',
//       },
//     };
//     return <TreeSelect {...tProps} />;
//   }
// }

// ReactDOM.render(<Demo />, mountNode);
// TreeComponent.propTypes = {
//     options: PropTypes.oneOfType([PropTypes.array]),
//     value: PropTypes.oneOfType([PropTypes.any]),
//     pId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//     treeData: PropTypes.oneOfType([PropTypes.any]),
//     onSelect: PropTypes.oneOfType([PropTypes.func]),
//     fieldNames: PropTypes.oneOfType([PropTypes.object]),
//     select: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
// }
// // 类似 shouldComponentUpdate()
// function shouldUpdate (prevProps, nextProps) {
//     const prevScroll = prevProps.scroll || {}
//     const nextprevScroll = nextProps.scroll || {}
//     return prevScroll.y !== nextprevScroll.y
// }
// export default React.memo(TreeComponent, shouldUpdate)


// ------------------------------------------------------------------------------------------------------------------------------------------------

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tree, Skeleton, TreeSelect, Cascader } from 'antd';
import { CarryOutOutlined } from '@ant-design/icons';
import api from '@/api/apiList';
import { arrayToTree, setTreeProps, findParentNodes } from '@/utils'
import { useCustomState } from '@/hooks/useEffect';
import { YzhEmpty } from '@com'
const { TreeNode } = TreeSelect;
const TreeComponent = (props) => {
    const [loading, setLoading] = useCustomState(true)
    let [state, setState] = useCustomState({
        treeData: props.options || [],
        value: props.value || undefined,
        pId: props.pId || undefined,
        defaultExpandedKeys: ['0'],
        refresh: false,
        arrayToTree: arrayToTree
    });
    const onSelect = (selectedKeys, info) => {
        // console.log(info, selectedKeys, 'ParentNodes', 'props:', props);
        props.onSelect(props.select ? selectedKeys : selectedKeys[0], props.select ? info : info.node)
    };
    const onChange = (selectedKeys, info) => {
        // console.log(info, selectedKeys, 'ParentNodes', 'props:', props);
        props.onChange(props.select ? selectedKeys : selectedKeys[0], props.select ? info : info.node)
    };

    useEffect(() => {
        let didUnmounted = false;
        if (didUnmounted) return
        const getTreeData = async () => {
            let res = await api.userCenter.department.queryCategoryTree({}, props.pcode)
            // let res = await api.userCenter.department.tree({}, props.pcode)
            let treeData = res && res.result !== null ? res.result : []
            treeData = arrayToTree(treeData, { children: 'children', pId: 'parentId', id: 'id' })
            console.log(treeData)
            // return
            let newExpandedKeys = []
            newExpandedKeys = setTreeProps(treeData).newExpandedKeys
            treeData = setTreeProps(treeData,
                (item) => {
                    // console.log([...findParentNodes(treeData, item.pid), item].map(el => el.name).join('/'), item.pid, 'item');
                    return ({
                        icon: <CarryOutOutlined />,
                        // label: item.pid ? [...findParentNodes(treeData, item.pid), item].map(el => el.name).join('/') : item.name
                        label: item.parentId ? [...findParentNodes(treeData, item.parentId, { children: 'children', pId: 'parentId', id: 'id' }), item].map(el => el.categoryName).join('/') : item.categoryName,
                        leaf: 'children'
                    })
                }).tree
            setState({
                value: props.value || undefined,
                pId: props.pId || undefined,
                treeData,
                defaultExpandedKeys: [...newExpandedKeys, props.value]
            })
            console.log(state, treeData)
            didUnmounted = true
            setLoading(false)
        }
        if (props.options && props.options.length) {
            setLoading(false)
            didUnmounted = true
            return
        }
        (loading || props.refresh) && !props.treeData && getTreeData()
        return () => {
            didUnmounted = true
        }
    }, [props, loading])
    const getTreeNode = (tree, params = { children: 'children', name: 'categoryName', id: 'id', label: 'label' }) => {
        const { children, name, id, label } = params
        return tree.map(el => {
            return <TreeNode value={el[id]} disabled={disabledId === el[id]} label={el[label]} key={el[id]} title={el[name]}>
                {
                    el[children] ? getTreeNode(el[children]) : null
                }
            </TreeNode>
        })
    }
    const { style, select, refresh, disabledId, ...parentProps } = props
    return (
        <div style={{ ...style }}>
            {((!loading && !refresh) || props.treeData) ? select ?
                <TreeSelect
                    {...parentProps}
                    // treeDefaultExpandedKeys={state.defaultExpandedKeys}
                    // treeDefaultExpandAll={false}
                    // onSelect={onSelect}
                    onChange={onChange}
                    // placeholder='请选择或输入分类'
                    value={props.value}
                    treeNodeLabelProp="label"
                    // defaultActiveFirstOption={true}
                    showSearch
                    // treeCheckable={true}
                    // multiple={true}
                    filterTreeNode={(input, option) => {
                        return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    allowClear
                // treeLine={true}
                >
                    {
                        getTreeNode(state.treeData)
                    }
                </TreeSelect> : (props.treeData?.length || state.treeData.length) ? <Tree
                    {...parentProps}
                    showLine={false}
                    showIcon={false}
                    fieldNames={{
                        title: 'name',
                        key: 'id',
                        children: 'nodes',
                        ...props.fieldNames
                    }}
                    treeDefaultExpandAll={true}
                    defaultExpandedKeys={state.defaultExpandedKeys}
                    value={props.value}
                    onSelect={onSelect}
                    treeData={props.treeData || state.treeData}
                    {...props}
                    style={null}
                /> : <YzhEmpty /> : !select && <Skeleton active paragraph={{ rows: 20 }} />}
        </div >
    );
};
TreeComponent.propTypes = {
    options: PropTypes.oneOfType([PropTypes.array]),
    value: PropTypes.oneOfType([PropTypes.any]),
    pId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    treeData: PropTypes.oneOfType([PropTypes.any]),
    onSelect: PropTypes.oneOfType([PropTypes.func]),
    fieldNames: PropTypes.oneOfType([PropTypes.object]),
    select: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    onChange: PropTypes.oneOfType([PropTypes.func]),
}
// 类似 shouldComponentUpdate()
function shouldUpdate (prevProps, nextProps) {
    const prevScroll = prevProps.scroll || {}
    const nextprevScroll = nextProps.scroll || {}
    return prevScroll.y !== nextprevScroll.y
}
export default React.memo(TreeComponent, shouldUpdate)