// 把所有的api文件夹导入到这一个文件中来
import common from './common'
import log from './log'
import app from './app'
import upload from './upload'
import setting from './setting'
import userCenter from './userCenter'
import importExportRecords from './importExportRecords'


const apiList = {
    common,
    log,
    app,
    setting,
    upload,
    userCenter,
    importExportRecords
}
export default apiList
export {
    common,
    log,
    app,
    setting,
    upload,
    userCenter,
    importExportRecords
}
